import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Image, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class CareersPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={null}
          subfooter={false}
          className={"about-page careers-page"}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            centered={true}
          >
            <Grid.Column width={14}>
              <Grid
                stackable={true}
                padded={true}
                columns={"2"}
                verticalAlign={"middle"}
                centered={true}
              >
                <Grid.Column textAlign={"left"}>
                  <h2>
                    There is one thing everyone on our team have in common: We
                    all love animals!
                  </h2>
                  <p>
                    Here at Nails 2 Tails we have a strong passion for all pets
                    and strive to provide the best quality and experience for
                    both our furry guests and their parents. We are always
                    looking for strong talent that fit our values to help take
                    Nails 2 Tails to the next level! If you are looking for an
                    exciting career where you get to enjoy what you do on a
                    daily basis send over your information!
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/Careers_1.png"
                    }
                    centered={true}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <Grid
                stackable={true}
                padded={true}
                columns={"2"}
                verticalAlign={"middle"}
                text-align={"center"}
              >
                <Grid.Column>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/Careers_2.png"
                    }
                    centered={true}
                  />
                </Grid.Column>
                <Grid.Column>
                  <h2>Why join the team?</h2>
                  <List
                    items={[
                      "Steady and growing client base",
                      "Competitive pay: Stable hourly + Commission + Tips",
                      "401k Benefits with company match!",
                      "Eligible for yearly raises",
                      "State of the art equipment like kennels, tables, dryers, and Hydrosurge Bather box",
                      "All the supplies you need including shampoos, sprays, tools, etc.",
                    ]}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <Grid
                stackable={true}
                padded={true}
                columns={"2"}
                text-align={"center"}
                verticalAlign={"middle"}
              >
                <Grid.Column>
                  <h2>APPLY TODAY!</h2>
                  <List>
                    <List.Item>Managing Director</List.Item>
                    <List.Item>Chris Vasquez</List.Item>
                    <List.Item
                      as={"a"}
                      href={"tel:+19563869386"}
                      target={"_blank"}
                    >
                      P: (956) 386-9386
                    </List.Item>
                    <List.Item
                      as={"a"}
                      href={"mailto:careers@nails2tailsllc.com"}
                      target={"_blank"}
                    >
                      E: careers@nails2tailsllc.com
                    </List.Item>
                    <List.Item>
                      <span>Please send your resume to &nbsp;</span>
                      <a
                        href={"mailto:careers@nails2tailsllc.com"}
                        target={"_blank"}
                        className={"apply-here"}
                      >
                        careers@nails2tailsllc.com
                      </a>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/Careers_3.png"
                    }
                    centered={true}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={8}>
              <ContactForm
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "birthdate",
                    placeholder: "Birthdate",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "howWereYouReferred",
                    displayLabel: "How were you referred to us?",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Walk-In", value: "Walk-In" },
                      { label: "Newspaper Ad", value: "Newspaper Ad" },
                      { label: "Twitter", value: "Twitter" },
                      { label: "Employee", value: "Employee" },
                      { label: "Facebook", value: "Facebook" },
                      { label: "Craigslist", value: "Craigslist" },
                      {
                        label: "Other (please specify)",
                        value: "Other (please specify)",
                      },
                    ],
                  },
                  {
                    displayLabel: "Others:",
                    label: "others:",
                    placeholder: "If others please specify:",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "position",
                    displayLabel: "What position are you applying for ?",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Pet Stylist", value: "Pet Stylist" },
                      {
                        label: "Grooming Apprentice",
                        value: "Grooming Apprentice",
                      },
                      { label: "Bather", value: "Bather" },
                      { label: "Receptionist ", value: "Receptionist " },
                    ],
                  },
                  {
                    label: "skills",
                    displayLabel: "Describe your skills",
                    placeholder: "Message",
                    type: "textarea",
                    required: true,
                  },
                  {
                    label: "yearsOfExperience",
                    displayLabel:
                      "How many years of relevant experience do you have?",
                    placeholder: "Message",
                    type: "textarea",
                    required: false,
                  },
                  {
                    label: "favoriteBreed",
                    displayLabel:
                      "How many dogs do you own? What’s your favorite breed?",
                    placeholder: "Message",
                    type: "textarea",
                    required: false,
                  },
                  {
                    label: "credibleGroomingSalon",
                    displayLabel:
                      "Have you ever worked under a credible grooming salon before? If so, where, for how long, and what position?",
                    placeholder: "Message",
                    type: "textarea",
                    required: false,
                  },
                  {
                    label: "referenceName1",
                    displayLabel: "Reference Name 1",
                    placeholder: "Reference Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "referencePhone1",
                    displayLabel: "Reference Phone Number 1",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "yearsKnown",
                    displayLabel: "Years Known",
                    placeholder: "Years Known",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "referenceName2",
                    displayLabel: "Reference Name 2",
                    placeholder: "Reference Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "referencePhone2",
                    displayLabel: "Reference Phone Number 2",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "yearsKnown",
                    displayLabel: "Years Known",
                    placeholder: "Years Known",
                    type: "input",
                    required: true,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Careers" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
